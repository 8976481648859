<template>
  <b-container
    id="terms-of-service"
    class="my-4"
  >
    <b-row>
      <b-col>
        <h2>Terms of Service</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>

        <div class="page-content">
          <div
            id="ez-toc-container"
            class="ez-toc-v2_0_36_1 counter-hierarchy ez-toc-counter ez-toc-light-blue ez-toc-container-direction"
          >
            <p class="ez-toc-title">
              Table of Contents
            </p>
            <nav><ul class="ez-toc-list ez-toc-list-level-1">
              <li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-1 __mPS2id _mPS2id-h mPS2id-highlight mPS2id-highlight-first mPS2id-highlight-last"
                  href="#ARTICLE_1_Acceptance_of_the_Website_Terms_and_Conditions_of_Use"
                  title="ARTICLE 1 Acceptance of the&nbsp;Website&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;of Use."
                >ARTICLE 1 Acceptance of the&nbsp;Website&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;of Use.</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-2 __mPS2id _mPS2id-h"
                  href="#ARTICLE_2_Modifications_to_the_Terms_and_Conditions_and_to_the_Website"
                  title="ARTICLE 2 Modifications to the&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and to the&nbsp;Website"
                >ARTICLE 2 Modifications to the&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and to the&nbsp;Website</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-3 __mPS2id _mPS2id-h"
                  href="#ARTICLE_3_Your_Use_of_the_Website_and_Account_Set-Up_and_Security"
                  title="ARTICLE 3 Your Use of the&nbsp;Website&nbsp;and Account Set-Up and Security"
                >ARTICLE 3 Your Use of the&nbsp;Website&nbsp;and Account Set-Up and Security</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-4 __mPS2id _mPS2id-h"
                  href="#ARTICLE_4_Intellectual_Property_Rights_and_Ownership"
                  title="ARTICLE 4 Intellectual Property Rights and Ownership"
                >ARTICLE 4 Intellectual Property Rights and Ownership</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-5 __mPS2id _mPS2id-h"
                  href="#ARTICLE_5_Conditions_of_Use_and_User_Submissions_and_Site_Content_Standards"
                  title="ARTICLE 5 Conditions&nbsp;of Use and User Submissions and Site Content Standards"
                >ARTICLE 5 Conditions&nbsp;of Use and User Submissions and Site Content Standards</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-6 __mPS2id _mPS2id-h"
                  href="#ARTICLE_6_User_Submissions_Grant_of_License"
                  title="ARTICLE 6 User Submissions: Grant of License"
                >ARTICLE 6 User Submissions: Grant of License</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-7 __mPS2id _mPS2id-h"
                  href="#ARTICLE_7_Site_Monitoring_and_Enforcement_Suspension_and_Termination"
                  title="ARTICLE 7 Site Monitoring and Enforcement, Suspension and Termination"
                >ARTICLE 7 Site Monitoring and Enforcement, Suspension and Termination</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-8 __mPS2id _mPS2id-h"
                  href="#ARTICLE_8_No_Reliance"
                  title="ARTICLE 8 No Reliance"
                >ARTICLE 8 No Reliance</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-9 __mPS2id _mPS2id-h"
                  href="#ARTICLE_9_Privacy"
                  title="ARTICLE 9 Privacy"
                >ARTICLE 9 Privacy</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-10 __mPS2id _mPS2id-h"
                  href="#ARTICLE_10_Third_Party_Websites"
                  title="ARTICLE 10 Third Party&nbsp;Websites"
                >ARTICLE 10 Third Party&nbsp;Websites</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-11 __mPS2id _mPS2id-h"
                  href="#ARTICLE_11_Online_Purchases"
                  title="ARTICLE 11 Online Purchases"
                >ARTICLE 11 Online Purchases</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-12 __mPS2id _mPS2id-h"
                  href="#ARTICLE_12_Geographic_Restrictions"
                  title="ARTICLE 12 Geographic Restrictions"
                >ARTICLE 12 Geographic Restrictions</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-13 __mPS2id _mPS2id-h"
                  href="#ARTICLE_13_Disclaimer_of_Warranties"
                  title="ARTICLE 13 Disclaimer of Warranties"
                >ARTICLE 13 Disclaimer of Warranties</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-14 __mPS2id _mPS2id-h"
                  href="#ARTICLE_14_Limitation_on_Liability"
                  title="ARTICLE 14 Limitation on Liability"
                >ARTICLE 14 Limitation on Liability</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-15 __mPS2id _mPS2id-h"
                  href="#ARTICLE_15_Indemnification"
                  title="ARTICLE 15 Indemnification"
                >ARTICLE 15 Indemnification</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-16 __mPS2id _mPS2id-h"
                  href="#ARTICLE_16_Governing_Law_and_Jurisdiction"
                  title="ARTICLE 16 Governing Law and Jurisdiction"
                >ARTICLE 16 Governing Law and Jurisdiction</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-17 __mPS2id _mPS2id-h"
                  href="#ARTICLE_17_Waiver"
                  title="ARTICLE 17 Waiver"
                >ARTICLE 17 Waiver</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-18 __mPS2id _mPS2id-h"
                  href="#ARTICLE_18_Severability"
                  title="ARTICLE 18 Severability"
                >ARTICLE 18 Severability</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-19 __mPS2id _mPS2id-h"
                  href="#ARTICLE_19_Entire_Agreement"
                  title="ARTICLE 19 Entire Agreement"
                >ARTICLE 19 Entire Agreement</a>
              </li><li class="ez-toc-page-1 ez-toc-heading-level-4">
                <a
                  class="ez-toc-link ez-toc-heading-20 __mPS2id _mPS2id-h"
                  href="#ARTICLE_20_REPORTING_AND_CONTACT"
                  title="ARTICLE 20 REPORTING AND CONTACT"
                >ARTICLE 20 REPORTING AND CONTACT</a>
              </li>
            </ul></nav></div>
          <h4><span
            id="ARTICLE_1_Acceptance_of_the_Website_Terms_and_Conditions_of_Use"
            class="ez-toc-section _mPS2id-t mPS2id-target mPS2id-target-first mPS2id-target-last"
          />ARTICLE 1 Acceptance of the&nbsp;Website&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;of Use.<span class="ez-toc-section-end" /></h4>
          <p>1.01 These website terms and conditions of use for https://www.converifai.com, constitute a legal agreement and are entered into by and between you and Converifai Technologies Inc. (“Company,” “we,” “us,” “our”). The following terms and conditions, together with any documents and/or additional terms they expressly incorporate by reference (collectively, these “Terms and Conditions”), govern your access to and use, including any content, functionality, and services offered on or through https://www.converifai.com (the “Website”).<br>
            1.02 By using the website, you accept and agree to be bound and comply with these terms and conditions and our privacy policy, found at https://converifai.com/privacy-policy/, incorporated herein by reference. If you do not agree to these&nbsp;terms&nbsp;and&nbsp;conditions&nbsp;or the privacy policy, you must not access or use the&nbsp;website.<br>
            1.03 By using this&nbsp;Website, you represent and warrant that you are the legal age of majority under applicable law to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the&nbsp;Website.</p>
          <h4><span
            id="ARTICLE_2_Modifications_to_the_Terms_and_Conditions_and_to_the_Website"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 2 Modifications to the&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and to the&nbsp;Website<span class="ez-toc-section-end" /></h4>
          <p>2.01 We reserve the right in our sole discretion to revise and update these&nbsp;terms&nbsp;and&nbsp;conditions&nbsp;from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the&nbsp;Website. You agree to periodically review the&nbsp;terms&nbsp;and&nbsp;conditions&nbsp;in order to be aware of any such modifications and your continued use shall be your acceptance of these.<br>
            2.02 The information and material on this&nbsp;Website, and the&nbsp;Website&nbsp;may be changed, withdrawn or terminated at any time in our sole discretion without notice. We will not be liable if for any reason all or any part of the&nbsp;Website&nbsp;is restricted to users or unavailable at any time or for any period.</p>
          <h4><span
            id="ARTICLE_3_Your_Use_of_the_Website_and_Account_Set-Up_and_Security"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 3 Your Use of the&nbsp;Website&nbsp;and Account Set-Up and Security<span class="ez-toc-section-end" /></h4>
          <p>3.01 Users are responsible for obtaining their own access to the&nbsp;Website&nbsp;and for the&nbsp;Website’s&nbsp;availability and performance. Users are required to ensure that all persons who access the&nbsp;Website&nbsp;through a user’s internet connection are aware of these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and comply with them. Users are responsible for any security breaches or performance issues relating to accessing the&nbsp;Website.<br>
            3.02 The&nbsp;Website&nbsp;including content or areas of the&nbsp;Website&nbsp;may require user registration. It is a&nbsp;condition&nbsp;of your use of the&nbsp;Website&nbsp;that all the information you provide on the&nbsp;Website&nbsp;is correct, current, and complete.<br>
            3.03 Your provision of registration information and any submissions you make to the Website through any functionality such as applications, chat rooms, e-mail, message boards, personal or interest group web pages, profiles, forums, bulletin boards and other such functions (collectively, “Interactive Functions”) constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy https://converifai.com/privacy-policy/.<br>
            3.04 Any user name, password, or any other piece of information chosen by you or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this&nbsp;Website&nbsp;or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.<br>
            3.05 We reserve the right at any time and from time to time, to disable or terminate your account, any user name, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these&nbsp;Terms&nbsp;and&nbsp;Conditions.<br>
            3.06 You are prohibited from attempting to circumvent and from violating the security of this&nbsp;Website&nbsp;including without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restrict disrupt or disable service to users, hosts, servers or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting&nbsp;Websiteowner’s ability to monitor the&nbsp;Website; (f) use any robot, spider, or other automatic device, process, or means to access the&nbsp;Website&nbsp;for any purpose, including monitoring or copying any of the material on the&nbsp;Website; (g) introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attack the&nbsp;Website&nbsp;via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing or crashing; and (i) otherwise attempt to interfere with the proper working of the&nbsp;Website.</p>
          <h4><span
            id="ARTICLE_4_Intellectual_Property_Rights_and_Ownership"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 4 Intellectual Property Rights and Ownership<span class="ez-toc-section-end" /></h4>
          <p>4.01 You understand and agree that the Website and its entire contents, features, and functionality, including but not limited to all information, software, code, text, displays, graphics, photographs, video, audio, design, presentation, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.<br>
            4.02 The Company name, the Company logo, and all related names, logos, product and service names, designs, images and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images and slogans mentioned or which appear on this&nbsp;Website&nbsp;are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute a violation of the rights of the property owner and may be a violation of federal or other laws and could subject the violator to legal action.<br>
            4.03 You may only use the&nbsp;Website&nbsp;for your personal and non-commercial use. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our&nbsp;Website, in any form or medium whatsoever except: (i) your computer and browser may temporarily store or cache copies of materials being accessed and viewed; (ii)a reasonable number of copies for personal use only may be printed keeping any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not for further reproduction, publication, or distribution of any kind on any medium whatsoever; and (iii) in the event&nbsp;social media features are provided with respect to certain content are on our site, you may take such actions as our site permits for such features.<br>
            4.04 Users are not&nbsp;permitted to modify copies of any materials from this site nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the&nbsp;Website&nbsp;or any services or materials available through the&nbsp;Website.<br>
            4.05 If you print off, copy or download any part of our site in breach of these&nbsp;Terms&nbsp;and&nbsp;Conditions, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the&nbsp;Website&nbsp;or to any content on the&nbsp;Website, and all rights not expressly granted are reserved by the Company. Any use of the&nbsp;Website&nbsp;not expressly permitted by these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;is a breach of these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and may violate copyright, trademark, and other intellectual property laws.</p>
          <h4><span
            id="ARTICLE_5_Conditions_of_Use_and_User_Submissions_and_Site_Content_Standards"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 5 Conditions&nbsp;of Use and User Submissions and Site Content Standards<span class="ez-toc-section-end" /></h4>
          <p>5.01 As a&nbsp;condition&nbsp;of your access and use you agree that you may use the&nbsp;Website&nbsp;only for lawful purposes and in accordance with these&nbsp;Terms&nbsp;and&nbsp;Conditions.<br>
            5.02 The following content standards apply to any and all content, material, and information a user submits, posts, publishes, displays, or transmits (collectively, “submit”) to the&nbsp;website, to other users or other persons (collectively, “User Submissions”) and any and all Interactive Functions. Any and all User Submissions must comply with all applicable federal, provincial, local, and international laws and regulations.<br>
            5.03 Without limiting the foregoing you warrant and agree that your use of the&nbsp;Website&nbsp;and any User Submissions shall not:</p>
          <p>(a) In any manner violate any applicable federal, provincial, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and our Privacy Policy [INSERT AS LINK TO PRIVACY POLICY];<br>
            (b) Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or other such prohibited ground or be otherwise objectionable;<br>
            (c) Involve stalking, attempting to exploit any individual or harm minors in any way by exposing them to inappropriate content or otherwise nor ask for personal information;<br>
            (d) Involve, provide or contribute any false, inaccurate or misleading information;<br>
            (e) Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does not comply with these&nbsp;Terms&nbsp;and&nbsp;Conditions;<br>
            (f) Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses, or screen names associated with any of the foregoing);<br>
            (g) Transmit, or procure the sending of, any advertisements or promotions [without our prior written consent], commercial activities or sales, including without limitation any “spam”, “junk mail”, “chain letter”, contests, sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation;<br>
            (h) Include engaging in any other conduct that restricts or inhibits anyone’s use or enjoyment of the&nbsp;Website, or which, as determined by us, may harm the Company or users of the&nbsp;Website&nbsp;or expose them to liability;<br>
            (i) Include causing annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm any other person;<br>
            (j) Promote any illegal activity, or advocate, promote, or assist any unlawful act; and/or<br>
            (k) Give the impression that they originate from or are endorsed by us or any other person or entity, if this is not the case.</p>
          <h4><span
            id="ARTICLE_6_User_Submissions_Grant_of_License"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 6 User Submissions: Grant of License<span class="ez-toc-section-end" /></h4>
          <p>6.01 The&nbsp;Website&nbsp;may contain Interactive Functions allowing User Submissions on or through the&nbsp;Website.<br>
            6.02 None of the User Submissions you submit to the&nbsp;Website&nbsp;will be subject to any confidentiality by the Company. By providing any User Submission to the&nbsp;Website, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive license to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You waive any moral rights or other rights of authorship as a&nbsp;condition&nbsp;of submitting any User Submission.<br>
            6.03 By submitting the User Submissions you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the license hereof to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these&nbsp;Terms&nbsp;and&nbsp;Conditions. You represent and warrant that all User Submissions comply with applicable laws and the User Submissions and Site Content Standards set out in these&nbsp;Terms&nbsp;and&nbsp;Conditions.<br>
            6.04 You understand and agree that you, not the Company, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable including to any third party for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the&nbsp;Website.</p>
          <h4><span
            id="ARTICLE_7_Site_Monitoring_and_Enforcement_Suspension_and_Termination"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 7 Site Monitoring and Enforcement, Suspension and Termination<span class="ez-toc-section-end" /></h4>
          <p>7.01 Company has the right, without provision of notice to:<br>
            (a) Remove or refuse to post on the&nbsp;Website&nbsp;any User Submissions for any or no reason in our sole discretion;<br>
            (b) At all times, to take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including without limitation, for violating the&nbsp;Website&nbsp;and User Submissions and Site Content Standards or&nbsp;Terms&nbsp;and&nbsp;Conditions;<br>
            (c) Take appropriate legal action, including without limitation, referral to law enforcement, regulatory authority or harmed party for any illegal or unauthorized use of the&nbsp;Website. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the&nbsp;Website; and/or<br>
            (d) Terminate or suspend your access to all or part of the&nbsp;Website&nbsp;for any or no reason, including without limitation, any violation of these&nbsp;Terms&nbsp;and&nbsp;Conditions.</p>
          <p>7.02 You waive and hold harmless the company and its affiliates, licensees and service provider from any and all claims resulting from any action taken by the company and any of the foregoing parties relating to any, investigations by either the company or such parties or by law enforcement authorities.<br>
            7.03 We have no obligation, nor any responsibility to any party to monitor the&nbsp;Website&nbsp;or use, and do not and cannot undertake to review material that you or other users submit to the&nbsp;Website. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party.</p>
          <h4><span
            id="ARTICLE_8_No_Reliance"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 8 No Reliance<span class="ez-toc-section-end" /></h4>
          <p>8.01 The content on our&nbsp;Website&nbsp;is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on our site.<br>
            8.02 Although we make reasonable efforts to update the information on our&nbsp;Website, we make no representations, warranties or guarantees, whether express or implied, that the content on our&nbsp;Website&nbsp;is accurate, complete or up to date. Your use of the&nbsp;Website&nbsp;is at your own risk and the Company has no responsibility or liability whatsoever for your use of this&nbsp;Website.<br>
            8.03 This&nbsp;Website&nbsp;may include content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. The Company is not responsible, or liable to you or any third party, for the content or accuracy of any third party materials.</p>
          <h4><span
            id="ARTICLE_9_Privacy"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 9 Privacy<span class="ez-toc-section-end" /></h4>
          <p>9.01 Any user information and User Submissions will be deemed our property and your submissions and use of our site constitutes consent to the collection, use, reproduction, hosting, transmission and disclosure of any such user content submissions in compliance with our Privacy Policy [INSERT LINK TO PRIVACY POLICY], as we deem necessary for use of the&nbsp;Website&nbsp;and provision of services.<br>
            9.02 By using this&nbsp;Website&nbsp;you are consenting to the use of cookies which allow a server to recall previous requests or registration and/or IP addresses to analyze&nbsp;website&nbsp;use patterns. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also set your browser to turn off cookies. If you do, however, some areas of the&nbsp;Website&nbsp;may not function adequately.</p>
          <h4><span
            id="ARTICLE_10_Third_Party_Websites"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 10 Third Party&nbsp;Websites<span class="ez-toc-section-end" /></h4>
          <p>10.01 For your convenience, this&nbsp;Website&nbsp;may provide links or pointers to third party sites. We make no representations about any other&nbsp;websites&nbsp;that may be accessed from this&nbsp;Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third party sites, and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any&nbsp;terms&nbsp;and&nbsp;conditions&nbsp;of such third party sites.<br>
            10.02 Such links to third party sites from the&nbsp;Website, may include links to certain social media features that enable you to link or transmit on your own or using certain third party&nbsp;websites, certain limited content from this&nbsp;Website. You may only use these features when they are provided by us and solely with respect to the content identified. Such features and links to third party sites are subject to any additional&nbsp;terms&nbsp;and&nbsp;conditions&nbsp;we provide with respect to such features.<br>
            10.03 You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the homepage. We reserve the right to withdraw linking permission without notice. The&nbsp;website&nbsp;in which you are linking must comply in all respects with the&nbsp;Conditions&nbsp;of Use and User Submissions and Site Content Standards. You agree to cooperate with us in causing any unauthorized framing or linking to immediately stop.</p>
          <h4><span
            id="ARTICLE_11_Online_Purchases"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 11 Online Purchases<span class="ez-toc-section-end" /></h4>
          <p>11.01 Certain sections of the Website may allow you to make purchases from us or from other merchants. If you make a purchase from us on or through our Website, all information obtained during your purchase or transaction and all of the information that you give as part of the transaction, such as your name, address, method of payment, credit card number, and billing information, may be collected by both us, the merchant, and our payment processing company. Please review our Privacy Policy for how we comply with securing your personal data.<br>
            11.02 Your participation, correspondence or business dealings with any affiliate, individual or company found on or through our Website, all purchase terms, conditions, representations or warranties associated with payment, refunds, and/or delivery related to your purchase, are solely between you and the merchant. You agree that we shall not be responsible or liable for any loss, damage, refunds, or other matters of any sort that incurred as the result of such dealings with a merchant.<br>
            11.03 Payment processing companies and merchants may have privacy and data collection practices that are different from ours. We have no responsibility or liability for these independent policies of the payment processing companies and merchants. In addition, when you make certain purchases through our Website, you may be subject to the additional terms and conditions of a payment processing company, merchant or us that specifically apply to your purchase. For more information regarding a merchant and its terms and conditions that may apply, visit that merchant’s Website and click on its information links or contact the merchant directly.<br>
            11.04 You release us, our affiliates, our payment processing company, and merchants from any damages that you incur, and agree not to assert any claims against us or them, arising from your purchase through or use of our Website.</p>
          <h4><span
            id="ARTICLE_12_Geographic_Restrictions"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 12 Geographic Restrictions<span class="ez-toc-section-end" /></h4>
          <p>12.01 The owner of the&nbsp;Website&nbsp;is based in the Province of Saskatchewan Canada. We provide this&nbsp;Website&nbsp;for use only by persons located in Canada. This site is not intended for use in any jurisdiction where its use is not permitted. If you access the site from outside Canada you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.</p>
          <h4><span
            id="ARTICLE_13_Disclaimer_of_Warranties"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 13 Disclaimer of Warranties<span class="ez-toc-section-end" /></h4>
          <p>13.01 You understand and agree that your use of the&nbsp;website, its content, and any services or items found or attained through the&nbsp;website&nbsp;is at your own risk. The&nbsp;website, its content, and any services or items found or attained through the&nbsp;website&nbsp;are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The foregoing does not affect any warranties that cannot be excluded or limited under applicable law.<br>
            13.02 Neither the company nor any affiliates nor their respective directors, officers, employees, agents or service providers makes any warranty, representation or endorsement with respect to the completeness, security, reliability, suitability, accuracy, currency or availability of the&nbsp;website&nbsp;or its contents. Without limiting the foregoing, neither the company nor any affiliates nor their respective directors, officers, employees, agents or service providers represents or warrants that the&nbsp;website, its content, or any services or items found or attained through the&nbsp;website&nbsp;will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that our site or the server that makes it available are free of viruses or other harmful components.<br>
            13.03 We cannot and do not guarantee or warrant that files or data available for downloading from the internet or the&nbsp;Website will be free of viruses or other destructive code. You are solely and entirely responsible for your use of the&nbsp;Website&nbsp;and your computer, internet and data security. To the fullest extent provided by law, we will not be liable for any loss or damage caused by denial-of-service attack, distributed denial-of-service attack, overloading, flooding, mailbombing or crashing, viruses, trojan horses, worms, logic bombs, or other technologically harmful material that may infect your computer equipment, computer programs, data, or other proprietary material due to your use of the&nbsp;website&nbsp;or any services or items found or attained through the&nbsp;website&nbsp;or to your downloading of any material posted on it, or on any&nbsp;website&nbsp;linked to it.</p>
          <h4><span
            id="ARTICLE_14_Limitation_on_Liability"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 14 Limitation on Liability<span class="ez-toc-section-end" /></h4>
          <p>14.01 UNDER NO CIRCUMSTANCE WILL THE COMPANY, ITS AFFILIATES AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR SERVICE PROVIDERS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, RELIANCE ON, THE&nbsp;WEBSITE, ANY LINKED&nbsp;WEBSITES&nbsp;OR SUCH OTHER THIRD PARTY&nbsp;WEBSITES, NOR ANY SITE CONTENT, MATERIALS, POSTING OR INFORMATION THEREON.</p>
          <h4><span
            id="ARTICLE_15_Indemnification"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 15 Indemnification<span class="ez-toc-section-end" /></h4>
          <p>15.01 To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company, its affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your breach of these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;or your use of the&nbsp;Website, including, but not limited to, your User Submissions, third party sites, any use of the&nbsp;Website’s&nbsp;content, services, and products other than as expressly authorized in these&nbsp;Terms&nbsp;and&nbsp;Conditions.</p>
          <h4><span
            id="ARTICLE_16_Governing_Law_and_Jurisdiction"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 16 Governing Law and Jurisdiction<span class="ez-toc-section-end" /></h4>
          <p>16.01 The&nbsp;Website&nbsp;and these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;will be governed by and construed in accordance with the laws of the Province of Saskatchewan and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle or rule (whether of the laws of the Province of Saskatchewan or any other jurisdiction) and notwithstanding your domicile, residence or physical location.<br>
            16.02 Any action or proceeding arising out of[or relating to this&nbsp;Website&nbsp;and under these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;will be instituted in the courts of the Province of Saskatchewan and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</p>
          <h4><span
            id="ARTICLE_17_Waiver"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 17 Waiver<span class="ez-toc-section-end" /></h4>
          <p>17.01 No waiver under these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;is effective unless it is in writing and signed by an authorized representative of the party waiving its right. No failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;operates, or may be construed, as a waiver thereof. No single or partial exercise of any right, remedy, power or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.</p>
          <h4><span
            id="ARTICLE_18_Severability"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 18 Severability<span class="ez-toc-section-end" /></h4>
          <p>18.01 If any&nbsp;term&nbsp;or provision of these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;is invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other&nbsp;term&nbsp;or provision of these&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;or invalidate or render unenforceable such&nbsp;term&nbsp;or provision in any other jurisdiction.</p>
          <h4><span
            id="ARTICLE_19_Entire_Agreement"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 19 Entire Agreement<span class="ez-toc-section-end" /></h4>
          <p>19.01 The&nbsp;Terms&nbsp;and&nbsp;Conditions&nbsp;and our Privacy Policy constitute the sole and entire agreement between you and [NAME OF STARTUP] regarding the&nbsp;Website&nbsp;and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, regarding such subject matter.</p>
          <h4><span
            id="ARTICLE_20_REPORTING_AND_CONTACT"
            class="ez-toc-section _mPS2id-t"
          />ARTICLE 20 REPORTING AND CONTACT<span class="ez-toc-section-end" /></h4>
          <p>20.01 This website is operated by Converifai Technologies Inc., 2030 11 ST SE, #101C, Calgary, AB T2G 3G3.<br>
            20.02 Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to the Company at info@converif.ai.<br>
            20.03 All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to support@converif.ai.</p>
          <p>Dated Effective Aug 23, 2022</p>
          <div class="post-tags" />
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  created() {
  },
}
</script>
